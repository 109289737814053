html,
body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: 'Inter', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 10%;
}

.App-logo {
  width: 100%;
  margin-bottom: 50px;
}

a {
  color: #000;
}
